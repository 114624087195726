.root {
  @apply text-black bg-white border-2 border-gray-400 ring-transparent;
  min-width: 100px;
}

.root:focus {
  @apply border-transparent border-black shadow-none ring-offset-0 ring-0;
}

.root.primary {
  @apply text-white bg-transparent bg-black border-2 border-white ring-transparent;
}

.root.primary:focus {
  @apply border-transparent border-white shadow-none ring-offset-0 ring-0;
}

.item {
  @apply text-base font-montserrat;
}

.option {
  @apply text-sm font-montserrat;
}

.option.primary {
  @apply text-white;
  background-color: rgb(30, 30, 30);
}
