.body {
  @apply text-sm font-normal font-montserrat;
}

.h1 {
  @apply text-4xl font-normal uppercase md:text-5xl font-cormorant;
}

.h2 {
  @apply text-2xl font-bold leading-8 uppercase md:text-3xl font-montserrat;
}

.h3 {
  @apply text-xl font-bold leading-5 font-montserrat;
}

.h4 {
  @apply text-base font-bold leading-5 font-montserrat;
}

.h5 {
  @apply text-sm font-semibold leading-4 font-montserrat;
}

.h6 {
  @apply text-xs font-semibold leading-3 font-montserrat;
}

.ol {
  @apply my-2 list-outside ml-4;

  li {
    @apply text-base font-semibold leading-5 font-montserrat list-decimal;
    text-align: -webkit-match-parent;

    ol li {
      @apply text-base font-semibold leading-5 font-montserrat;
      list-style-type: lower-roman;
    }

    p {
      @apply text-sm font-normal;
    }

    p:not(:first-child) {
      padding-left: 1em;
      text-indent: -1em;
    }
  }
}

.ul {
  @apply my-2 list-outside ml-4;
  li {
    @apply text-base font-semibold leading-5 font-montserrat list-disc;
    text-align: -webkit-match-parent;

    p {
      @apply text-base font-normal;
      padding-left: 1em;
      text-indent: -1em;
    }

    p:not(:first-child) {
      padding-left: 1em;
      text-indent: -1em;
    }
  }
}

.table {
  @apply w-full;

  th {
    @apply bg-primary py-4;
  }

  td {
    @apply p-4;
  }
}

.label {
  @apply text-xs font-semibold font-montserrat;
}

.link {
  @apply text-sm font-normal leading-3 text-white underline cursor-pointer font-montserrat;
}

.border {
  @apply uppercase border-b border-gray-200;
}

.stroke {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
