.root {
  @apply inline-flex items-center justify-center px-4 py-2 text-white border-2 border-transparent bg-primary min-w-[120px] min-h-[40px] hover:text-white hover:bg-gray-800;
}

.submit {
  @apply text-white bg-red-600 hover:text-white hover:bg-red-700;
}

.button {
  @apply text-black bg-white hover:text-black hover:bg-gray-200;
}

.loading {
  @apply bg-gray-700 border-gray-700 cursor-not-allowed hover:bg-gray-700;
}

.disabled {
  @apply text-white bg-gray-700 cursor-not-allowed hover:bg-gray-700 hover:text-white;
}
