.root {
  @apply w-full min-w-0 px-4 py-2 text-base text-black bg-white border-2 border-gray-400 rounded-none appearance-none font-montserrat;
}

.root:focus {
  @apply border-black outline-none ring-0;
}

.root.primary {
  @apply text-white placeholder-gray-500 border-white bg-primary;
  color-scheme: dark;
}

.root.primary:focus {
  @apply placeholder-gray-400 border-white;
}

.root.error {
  @apply border-red-500 focus:border-red-500;
}

.root::-webkit-input-placeholder {
  @apply text-sm;
}
