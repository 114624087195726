@keyframes rotation-spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.root {
  border-radius: 100%;
  border-style: solid;
  border-image: initial;
  display: inline-block;
  animation: rotation-spinner 1.5s linear 0s infinite;
  background: transparent !important;
}